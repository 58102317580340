<template>
  <div class=" w-full p-2">
    <Header />
    <span class="text-2xl font-bold mr-3 text-green-600 block">Spediti</span>
     <button class="bg-purple-400 block px-2 py-2 text-black rounded-md mt-2" @click="getOrders()">
          <img class="inline mr-1" src="@/assets/img/refresh-cw.svg" /> Ricarica
      </button>

<datepicker  locale="it" v-model="from" format="dd/MM/yyyy" cancelText="Annulla" selectText="Seleziona" :enableTimePicker="false" :closeOnAutoApply="true" autoApply  placeholder="Seleziona Data" class="inline-block border border-gray-400 rounded-md p-1 mt-2 mr-2"></datepicker>
 
    <datepicker locale="it" v-model="to" format="dd/MM/yyyy" cancelText="Annulla" selectText="Seleziona" :enableTimePicker="false" :closeOnAutoApply="true" autoApply  placeholder="Seleziona Data" class="inline-block border border-gray-400 rounded-md p-1"></datepicker>

    <div v-for="order in shipped" :key="order.id" class="border border-gray-400 rounded-md p-2 my-2 flex flex-row items-center">
      <div class="w-3/4">
          <b>{{order.customer}}</b><br/>
          <div v-for='addr in order.address.split(";")'>{{addr}}</div>
          <a :href="'mailto:'+order.email" v-if="order.email">{{order.email}}</a><br>
          {{order.items.replace(/;/g," - ")}}<br/>
          &euro;{{order.total}} <br/>
          {{dateLocale(order.date)}} 
      </div>
      <div class="w-1/4 text-right">
        <button class="py-1 px-2 bg-yellow-500 rounded-md text-black" @click="setToShip(order)">Segna come da spedire</button>
      </div>
    </div>

    <div v-if="!shipped.length" class="w-full text-center py-6 text-xl text-gray-700">Nessun ordine trovato</div>

    
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import axios from 'axios';

export default {
  name: 'Home',
  components: {
    Header,
    Datepicker
  },
  data:()=>({
    shipped:[],
    from:"",
    to:""
  }),
  methods:{
    getOrders(){

      axios.post("https://api.aquadijesolo.com/shipped",{
              start:this.from.toISOString(),
              end:this.to.toISOString(),
      },{
        headers: {
          'Authorization': 'Bearer '+window.localStorage.getItem("token") || ""
        }
      })
      .then(res=>{
        console.log(res);
        this.shipped = res.data;
      })
      .catch(err=>{
        console.error(err);
      })

    },

    dateLocale(str){
      const d = new Date(str);
      return d.toLocaleDateString('it-IT')
    },

    setToShip(order){
        order.shipped = false;

        axios.post("https://api.aquadijesolo.com/updateOrder",{
            order
        },
        {
        headers: {
          'Authorization': 'Bearer '+window.localStorage.getItem("token") || ""
        }
      })
        .then(res=>{
            this.getOrders();
        })
    },
    checkToken(){
      const token = window.localStorage.getItem("token");
      if(!token){
        this.$router.push("/accedi")
      }else{
        this.getOrders();
      }
    }
  },
  computed:{
    toShip(){
      return this.$store.state.toShip;
    }
  },
  created(){

    var date = new Date();
    var firstDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
    var lastDay = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1,0,22,59,59)); 

    this.from = firstDay;
    this.to   = lastDay;

    this.checkToken();
   
    
  }

}
</script>
