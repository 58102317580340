<template>
  <div class=" w-full p-2">
    <Header />
    <span class="text-2xl font-bold mr-3 text-orange-600 block">Coupon</span>
     

<datepicker  locale="it" v-model="from" format="dd/MM/yyyy" cancelText="Annulla" selectText="Seleziona" autoApply  placeholder="Seleziona Data" class="inline-block border border-gray-400 rounded-md p-1 mt-2 mr-2"></datepicker>
 
    <datepicker locale="it" v-model="to" format="dd/MM/yyyy" cancelText="Annulla" selectText="Seleziona" autoApply placeholder="Seleziona Data" class="inline-block border border-gray-400 rounded-md p-1"></datepicker>
    <input type="text" v-model="code" class="inline-block border border-gray-400 rounded-md mx-2 p-1" placeholder="Codice Coupon" />
    <br>
    <button class="bg-purple-400 inline-block px-2 py-2 text-black rounded-md mt-2 mr-2" @click="getOrders()">
          <img class="inline mr-1" src="@/assets/img/search.svg" /> Cerca
      </button>

      <download-csv class="bg-blue-400  px-2 py-2 inline-block text-black rounded-md mt-2"
              :data = "JSON.stringify(orders)">
              <img class="inline mr-1" src="@/assets/img/download.svg" />  Scarica CSV
          </download-csv>

    <div v-for="order in orders" :key="order.id" class="border border-gray-400 rounded-md p-2 my-2 flex flex-row items-center">
      <div class="w-3/4">
          <b>{{order.customer}}</b><br/>
          <div v-for='addr in order.address.split(";")'>{{addr}}</div>
          <a :href="'mailto:'+order.email" v-if="order.email">{{order.email}}</a><br>
          {{order.items.replace(/;/g," - ")}}<br/>
          &euro;{{order.total}} <br/>
          {{dateLocale(order.date)}} 
      </div>
    </div>

    <div v-if="!orders.length" class="w-full text-center py-6 text-xl text-gray-700">Nessun ordine trovato</div>

   

    
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import axios from 'axios';
import JsonCSV from 'vue-json-csv';

export default {
  name: 'Home',
  components: {
    Header,
    Datepicker,
    'downloadCsv': JsonCSV
  },
  data:()=>({
    orders:[],
    from:"",
    to:"",
    code:""
  }),
  methods:{
    getOrders(){

      axios.post("https://api.aquadijesolo.com/coupon",{
              start:this.from.toISOString(),
              end:this.to.toISOString(),
              code: this.code
      },{
        headers: {
          'Authorization': 'Bearer '+window.localStorage.getItem("token") || ""
        }
      })
      .then(res=>{
        this.orders = res.data;
      })
      .catch(err=>{
        console.error(err);
      })

    },

    dateLocale(str){
      const d = new Date(str);
      return d.toLocaleDateString('it-IT')
    },

    checkToken(){
      const token = window.localStorage.getItem("token");
      if(!token){
        this.$router.push("/accedi")
      }else{
        this.getOrders();
      }
    }
  },
  computed:{
    
  },
  created(){

    var date = new Date();
    var firstDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
    var lastDay = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1,0,22,59,59)); 

    this.from = firstDay;
    this.to   = lastDay;

    this.checkToken();
   
    
  }

}
</script>
