<template>
  <header class="w-full flex flex-row text-white p-2 rounded-md flex-wrap">

      <router-link to="/" class="bg-white px-2 py-2 text-black rounded-md mr-2">
          <img class="inline mr-1" src="@/assets/img/package.svg" /> Da Spedire
      </router-link>

       <router-link to="/spediti" class="bg-white px-2 py-2 text-black rounded-md mr-2">
          <img class="inline mr-1" src="@/assets/img/check.svg" />  Spediti
       </router-link>

       <router-link to="/coupon" class="bg-white px-2 py-2 text-black rounded-md mr-2">
          <img class="inline mr-1" src="@/assets/img/percent.svg" />  Coupon
       </router-link>


       <router-link to="/logout" class="bg-white px-2 py-2 text-black rounded-md mr-2 ml-auto">
          <img class="inline mr-1" src="@/assets/img/log-out.svg" />  Esci
       </router-link>
  </header>
</template>

<script>
export default {

}
</script>

<style>
    header{
        background: #2c3e50;
    }
</style>