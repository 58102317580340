<template>
  <div class=" w-full p-2">
    <Header />
    <span class="text-2xl font-bold mr-3 text-red-600 block">Da spedire </span>
     <button class="bg-purple-400 px-2 py-2 text-black rounded-md mt-2" @click="getOrders()">
          <img class="inline mr-1" src="@/assets/img/refresh-cw.svg" /> Ricarica
      </button>


    <div v-for="order in toShip" :key="order.id" class="border border-gray-400 rounded-md p-2 my-2 flex flex-row items-center">
      <div class="w-3/4">
          <b>{{order.customer}}</b><br/>
           <div v-for='addr in order.address.split(";")' :key="order.date">{{addr}}</div>
          <a :href="'mailto:'+order.email" v-if="order.email">{{order.email}}</a><br>
          {{order.items.replace(/;/g," - ")}}<br/>
          &euro;{{order.total}} <br/>
          {{dateLocale(order.date)}} 
      </div>
      <div class="w-1/4 text-right">
        <button class="py-1 px-2 bg-red-500 rounded-md text-white" @click="setShipped(order)">Segna come spedito</button>
      </div>
    </div>

    <div v-if="!toShip.length" class="w-full text-center py-6 text-xl text-gray-700">Nessun ordine trovato</div>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';

import axios from 'axios';

export default {
  name: 'Home',
  components: {
    Header
  },
  data:()=>({
    toShip:[]
  }),
  methods:{
    getOrders(){

      axios.get("https://api.aquadijesolo.com/toShip",{
        headers: {
          'Authorization': 'Bearer '+window.localStorage.getItem("token") || ""
        }
      })
      .then(res=>{
       
        this.toShip = res.data;
      })
      .catch(err=>{
        console.error(err);
      })

    },

    dateLocale(str){
      const d = new Date(str);
      return d.toLocaleDateString('it-IT')
    },
    setShipped(order){
        order.shipped = true;

        axios.post("https://api.aquadijesolo.com/updateOrder",{
            order
        },{
        headers: {
          'Authorization': 'Bearer '+window.localStorage.getItem("token") || ""
        }
      })
        .then(res=>{
            this.getOrders();
        })
    },
    checkToken(){
      const token = window.localStorage.getItem("token");
      if(!token){
        this.$router.push("/accedi")
      }else{
        this.getOrders();
      }
    }
  },
  computed:{
   
  },
  created(){
    this.checkToken();
    
  }

}
</script>
