import Vuex from 'vuex'


export default Vuex.createStore({
  state: {
    toShip:[]
  },
  mutations: {
    
  },
  actions: {
  },
  modules: {
  }
});
