import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue'
import Shipped from '../views/Shipped';
import Login from '../views/Login';
import Coupon from '../views/Coupon';
import Logout from '../views/Logout';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path:"/spediti",
    name:"Shipped",
    component:Shipped,
  },
  {
    path:"/accedi",
    name:"login",
    component:Login,
  },
  {
    path:"/coupon",
    name:"coupon",
    component:Coupon,
  },
  {
    path:"/logout",
    name:"logout",
    component:Logout,
  }
   
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
