<template>
  <div class=" w-full p-2">
   ciao

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';

export default {
  name: 'Logout',
  components: {
    Header
  },
  data:()=>({
    
  }),
  methods:{
    
  },
  computed:{
   
  },
  created(){
    window.localStorage.removeItem("token");
    this.$router.push("/accedi");    
  }

}
</script>
