<template>
  <div class="flex flex-row justify-center items-center w-screen h-screen">
    <div class="bg-white flex flex-col px-3 text-lg w-full md:w-1/3 m-2 pt-8 pb-10">
      <img src="@/assets/img/logo.svg" class="w-full block mb-8" />
      
      <h1 class="font-bold text-2xl mb-4">Accedi al pannello</h1>
     
      <label for="username">Nome Utente</label>
      <input id="username" type="text" class="border border-black px-1 py-1" v-model="user" />

      <label class="mt-4" for="password">Password</label>
      <input id="password" type="password" class="border border-black px-1 py-1" v-model="password" />

      <button @click="login()" class="bg-white border border-black text-black px-2 py-2 mt-4">ACCEDI</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data: () => ({
    user: "",
    password: "",
  }),
  methods: {
    login() {
      axios
        .post(
          "https://api.aquadijesolo.com/login",
          {
            user: this.user,
            password: this.password,
          },
          {
            headers: {
              authorization: window.localStorage.getItem("token") || "",
            },
          }
        )
        .then((res) => {
          window.localStorage.setItem("token", res.data);
          this.$router.push("/")
        })
        .catch((err) => {
          console.error(err);
        });
    },
   
  },
  created() {
     
  },
};
</script>

<style>
</style>